<template>
    <div class="container-fluid">
        <Quote/>
        <GoBack next="Mavjud modullar"/>

        <div class="row justify-content-center mb-0 pb-0">
            <div class="col-11">
                <div ref="top"></div>
                <table class="table table-hover border">
                    <thead>
                    <tr>
                        <th class="pl-1 pl-md-5 d-none d-md-table-cell">
                            <input
                                v-model="searchCourseInformation.id"
                                @keyup.enter="searchCourseByPage"
                                type="number"
                                placeholder="Id"
                                style="width: 110px;border-radius: 5px;height: 28px;"
                            />
                        </th>
                        <th class="pl-1 pl-sm-5 d-none d-md-table-cell">
                            <input
                                v-model="searchCourseInformation.courseName"
                                @keyup.enter="searchCourseByPage"
                                type="text"
                                placeholder="Kurs nomi"
                                style="width: 110px;border-radius: 5px;height:28px;"
                            />
                        </th>
                        <th class="pl-1 pl-sm-5">
                            <input
                                v-model="searchCourseInformation.name"
                                @keyup.enter="searchCourseByPage"
                                type="text"
                                placeholder="Modul nomi"
                                style="width: 110px;border-radius: 5px;height:28px;"
                            />
                        </th>
                        <th>
                            <select
                                v-model="searchCourseInformation.isActive"
                                class="dropdown-toggle"
                                @change="searchCourseByPage"
                                style="width: 115px;border-radius: 4px;height: 25px"
                            >
                                <option value="null">Barchasi</option>
                                <option value="true">Active</option>
                                <option value="false">Arxiv</option>
                            </select>
                        </th>
                        <th class="d-none d-sm-table-cell d-xl-table-cell">Qolgan joylar</th>
                        <th class="col-1 d-none d-md-table-cell d-xl-table-cell">
                            <input
                                v-model="searchCourseInformation.givenName"
                                @keyup.enter="searchCourseByPage"
                                type="text"
                                placeholder="O'qituvchi Ismi"
                                style="width: 110px;border-radius: 5px;height: 28px;"
                            />
                        </th>
                        <th class="col-2 d-none d-xl-table-cell">
                            <input
                                v-model="searchCourseInformation.familyName"
                                @keyup.enter="searchCourseByPage"
                                type="text"
                                placeholder="O'qituvchi Familyasi"
                                style="width: 130px;border-radius: 5px;height: 28px;"
                            />
                        </th>
                        <th class="col-2 px-0 px-sm-3">
                            <div class="d-inline-flex">
                                <b-button
                                    v-b-tooltip.hover
                                    title="Izlash"
                                    class="border-0 p-0"
                                    style="background-color: #E5E5E5; font-size:1.6vw;"
                                >
                                    <span>
                                      <img
                                          @click="searchCourseByPage"
                                          style=" height: auto; cursor: pointer; font-size:1.6vw;"
                                          src="../assets/search-icon.png"
                                          alt=""
                                      >
                                    </span>
                                </b-button>
                                <b-button
                                    v-b-tooltip.hover
                                    title="Tozalash"
                                    class="border-0 p-0 ms-0 ms-sm-3"
                                    style="background-color: #E5E5E5; font-size:1.7vw;"
                                >
                                    <span>
                                      <img
                                          @click="resetSearchCourseByPage"
                                          style=" height: auto; cursor: pointer; font-size:1.7vw;"
                                          src="../assets/eraser-icon.png"
                                          alt=""
                                      >
                                    </span>
                                </b-button>
                            </div>

                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr
                        class="pl-3 pt-3 pb-3"
                        v-for="comment in getTakeCourses"
                        :key="comment.id"
                    >
                        <td class="pl-1 pl-sm-5 d-none d-md-table-cell"
                        >
                            {{ comment.id}}
                        </td>
                        <td class="pl-1 pl-sm-5 d-none d-md-table-cell"
                        >
                            {{ comment.module.name}}
                        </td>
                        <td class="pl-2 pl-sm-5">
                            <span
                                v-if="comment.isActive"
                                class="btn text-primary agree-btn"
                            >
                                {{ comment.name }}
                            </span>
                            <span
                                v-else
                                class="btn text-danger agree-btn"
                            >
                                {{ comment.name }}
                            </span>
                        </td>
                        <td v-if="comment.isActive">
                            <select
                                @change="archiving(comment.id)"
                                class="text-primary agree-btn"
                                style="width: 115px;border-radius: 5px;height: 71%;border-color: black;text-align: left"
                            >
                                <option>
                                    Aktiv
                                </option>
                                <option>
                                    <button
                                        v-if="comment.isActive"
                                        style="width: 110px"
                                        type="button"
                                    >
                                        Arxivga olish
                                    </button>
                                </option>
                            </select>
                        </td>
                        <td v-else>
                            <select
                                @change="unArchive(comment.id)"
                                class="text-danger agree-btn"
                                style="width: 115px;border-radius: 5px;height: 71%;border-color: black;text-align: left"
                            >
                                <option>
                                    Arxiv
                                </option>
                                <option>
                                    <button
                                        style="width: 110px"
                                        type="button"
                                    >
                                        Arxivdan chiqarish
                                    </button>
                                </option>
                            </select>
                        </td>
                        <td class="text-dark pl-4 d-none d-sm-table-cell d-xl-table-cell">
                            {{ comment.countOfStudents - comment.members }} / {{ comment.countOfStudents }}
                        </td>

                        <td class="d-none d-md-table-cell" v-if="comment.teacher !== null">
                            {{ comment.teacher.givenName }}
                        </td>
                        <td class="d-none d-md-table-cell" v-else=""></td>
                        <td class="d-none d-xl-table-cell" v-if="comment.teacher !== null">
                            {{ comment.teacher.familyName }}
                        </td>
                        <td class="d-none d-xl-table-cell" v-else=""></td>
                        <td class="ps-0 ps-sm-2">
                            <router-link
                                :to="'/edit-module/' + comment.id"
                                :course="comment"
                            >
                                <img
                                    style=" height: auto; cursor: pointer; font-size:1.7vw;"
                                    class="ms-0 ms-sm-2 me-0 me-sm-3"
                                    src="../assets/pen.png"
                                    alt=""
                                >
                            </router-link>
                            <button
                                    @click="showModal(comment.id)"
                                    type="button"
                                    style="border: none; background-color: white; font-size:1.5vw;"
                            >
                                <img src="../assets/trash.png"/>
                            </button>
                        </td>
                    </tr>
                    </tbody>
                </table>
                <div>
                    <b-pagination
                        v-if="getTakeCoursesTotal >= 19"
                        class="mt-0 mb-5"
                        v-model="currentPage"
                        pills
                        align="center"
                        :per-page="20"
                        :total-rows="getTakeCoursesTotal"
                        @input="fetchCurrentCoursesByPage"
                        :click-handler="fetchCurrentCoursesByPage"
                    >
                    </b-pagination>
                </div>
                <div>
                    <b-modal ref="modal-success" hide-footer title="Kadirov.Dev">
                        <div class="d-block text-center">
                            <span>
                                <h3>Ushbu modul arxivga olindi</h3>
                            </span>
                        </div>
                        <b-button
                            class="mt-3 float-end"
                            style="width: 100px"
                            variant="btn btn-primary"
                            size="md"
                            @click="hide"
                        >
                            OK
                        </b-button>
                    </b-modal>
                </div>
                <div>
                    <b-modal ref="modal-successful" hide-footer title="Kadirov.Dev">
                        <div class="d-block text-center">
                            <span>
                                <h3>Ushbu modul arxivdan chiqarildi</h3>
                            </span>
                        </div>
                        <b-button
                            class="mt-3 float-end"
                            style="width: 100px"
                            variant="btn btn-primary"
                            size="md"
                            @click="shownArchive"
                        >
                            OK
                        </b-button>
                    </b-modal>
                </div>
                <div>
                    <b-modal ref="modal-success-delete" hide-footer title="Kadirov.Dev">
                        <div class="d-block text-center">
                            <h3>Ushbu modulni rostdan ham o'chirmoqchimisiz?</h3>
                        </div>
                        <b-button
                                class="mt-3 p-1" style="width: 100px"
                                variant="btn btn-primary"
                                size="lg"
                                @click="hideModal"
                        >
                            Yo'q
                        </b-button>
                        <b-button
                                class="mt-3 p-1 float-end"
                                style="width: 100px"
                                variant="btn btn-danger"
                                size="lg"
                                @click="deleteModule"
                        >
                            Ha
                        </b-button>
                    </b-modal>
                </div>
            </div>
        </div>
        <b-overlay :show="show" no-wrap>
        </b-overlay>
    </div>
</template>

<script>
import GoBack from "../components/GoBack";
import Quote from "../components/Quote";
import {mapActions, mapGetters} from "vuex";

export default {
    name: "CourseStudentPage",
    components: {
        Quote,
        GoBack
    },
    data() {
        return {
            show: true,
            currentPage: 1,
            courseTypeId: null,
            searchCourseInformation: {
                courseName: '',
                name: '',
                givenName: '',
                familyName: '',
                isActive: null,
                page: 1
            },
            courseId: null
        }
    },
    watch: {
        '$route.params.page': {
            handler: function (val) {
                this.currentPage = val ? val : 1
                this.fetchTakeCourses(this.currentPage)
            },
            deep: true,
            immediate: true
        }
    },
    methods: {
        ...mapActions([
            'fetchTakeCourses',
            'stopCourseAdmission',
            'searchCourseInfo',
            'deleteCourse'
        ]),
        deleteModule() {
            this.deleteCourse(this.courseId)
                .then(() => {
                    this.$refs['modal-success-delete'].hide()
                    this.fetchTakeCourses(this.currentPage)
                })
        },
        fetchCurrentCoursesByPage() {
            this.$router.push('/course-archive/page/' + this.currentPage)
            this.$refs.top.scrollIntoView()
        },
        searchCourseByPage() {
            this.show = true
            this.searchCourseInfo(this.searchCourseInformation)
                .catch((err) => {
                    console.log(err)
                })
                .finally(() => {
                    this.show = false
                });
        },
        resetSearchCourseByPage() {
            Object.assign(this.$data, this.$options.data.call(this));
            this.searchCourseByPage()
        },
        archiving(courseId) {
            this.stopCourseAdmission({id: courseId, isActive: false})
                .then(() => {
                    this.hide()
                    this.searchCourseInfo(this.searchCourseInformation)
                    this.$refs['modal-success'].show()
                })
        },
        unArchive(courseId) {
            this.stopCourseAdmission({id: courseId, isActive: true})
                .then(() => {
                    this.shownArchive()
                    this.searchCourseInfo(this.searchCourseInformation)
                    this.$refs['modal-successful'].show()
                })
        },
        showModal(id) {
            this.courseId = id
            this.$refs['modal-success-delete'].show()
        },
        hideModal() {
            this.$refs['modal-success-delete'].hide()
        },
        hide() {
            this.$refs['modal-success'].hide()
        },
        shownArchive() {
            this.$refs['modal-successful'].hide()
        },

    },
    computed: {
        ...mapGetters(['getTakeCourses', 'getTakeCoursesTotal'])
    },
    mounted() {
        this.show = true
        this.fetchTakeCourses(this.currentPage)
        this.show = false
    }
}
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Comfortaa&family=Open+Sans:wght@400;600&display=swap');

* {
    margin: 0;
    padding: 0;
}

img {
    width: 20px;
    height: 20px;
}

.agree-btn {
    font-size: 12px;
    font-family: Comfortaa, cursive;
    padding: 5px;
}

.row {
    margin-top: 111px;
}

table {
    margin-bottom: 48px;
}

select {
    box-shadow: 0 0 0 1px black;
    padding-left: 10px;
}

select:focus {
    border: none;
    outline: 2px solid #b505b4;
}

input {
    padding: 5px;
    border: 1px solid black;
}

input::placeholder {
    padding: 0.1rem;
    font-size: 12px;
}

input:focus {
    border: none;
    outline: 2px solid #b505b4;
}

table th {
    height: 60px;
    font-family: 'Open Sans', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    text-align: left;
    padding-bottom: 1.2rem;
    background-color: #E5E5E5;
    color: rgba(0, 0, 0, 0.5);
}

tbody tr {
    height: 60px;
    vertical-align: text-top;
}

table tr select {
    margin-right: 10px;
    border: none;
}

table tbody tr td {
    font-family: 'Open Sans', sans-serif;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 1px;

    background-color: #FFFFFF;
    color: #000000;
}

</style>
